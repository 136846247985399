import { Callback } from '../../../types';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, LocalDataTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import { drawing_line, participantConnected, participantDisconnected } from './annotation';
// import useWindowDimensions from '../../../state/settings/windowDimensions';
// const colorHash = new (require('color-hash'))();

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const dataTrack = new LocalDataTrack();
  //const { height, width } = useWindowDimensions();

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  // async function setupLocalDataTrack() {

  // return dataTrack;
  // }

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      const canvas = document.getElementById('canvas') as HTMLCanvasElement;

      let width = canvas.width;
      let height = canvas.height;

      //console.log('canvas  { height, width } =>', { height, width });

      let mouseDown: any;
      let msg: any;
      let mouseCoordinates: any;
      let x: any;
      let y: any;
      let actionCode: any;

      window.addEventListener(
        'mousedown',
        event => {
          mouseDown = true;
          msg = 'START_ANNOTATION';
          // dataTrack.send(msg);
          x = event.offsetX;
          y = event.offsetY;

          width = canvas.width;
          height = canvas.height;

          //console.log('canvas  { height, width } from mousedown =>', { height, width });

          mouseCoordinates = { x: x / width, y: y / height, xM: x / width, yM: y / height };
          actionCode = 0;

          dataTrack.send(
            JSON.stringify({
              mouseDown,
              mouseCoordinates,
              actionCode,
            })
          );
        },
        false
      );

      window.addEventListener(
        'mouseup',
        () => {
          mouseDown = false;
        },
        false
      );

      document.addEventListener('mousemove', evt => {
        let screenshot = document.getElementById('screenshot-base64');
        if (screenshot) {
          // console.log('screenshot', screenshot);
          if (mouseDown) {
            let xM = evt.offsetX;
            let yM = evt.offsetY;
            //{ (x / width), (y / height), (xM / width), (yM / height) }

            width = canvas.width;
            height = canvas.height;

            //console.log('canvas  { height, width } from mousemove =>', { height, width });

            mouseCoordinates = { x: x / width, y: y / height, xM: xM / width, yM: yM / height };
            actionCode = 2;

            //console.log('after height width divison multiplication  mouseCoordinates =>', mouseCoordinates);

            const color = '#FF00F7';
            drawing_line(color, x, y, xM, yM, canvas);
            dataTrack.send(
              JSON.stringify({
                mouseDown,
                mouseCoordinates,
                actionCode,
              })
            );
            x = xM;
            y = yM;
          }
        }
      });

      // window.addEventListener(
      //   'mousemove',
      //   event => {
      //     const { pageX: x, pageY: y } = event;
      //     mouseCoordinates = { x, y };

      //     if (mouseDown) {
      //       const color = colorHash.hex(dataTrack.id);
      //       drawCircle(canvas, color, x, y);

      //       dataTrack.send(
      //         JSON.stringify({
      //           mouseDown,
      //           mouseCoordinates,
      //         })
      //       );
      //     }
      //   },
      //   false
      // );
      // const dataTrack = setupLocalDataTrack();
      return Video.connect(token, { ...options, tracks: [...localTracks, dataTrack] }).then(
        newRoom => {
          setRoom(newRoom);
          // main();
          const disconnect = () => newRoom.disconnect();

          newRoom.participants.forEach(participantConnected);
          newRoom.on('participantConnected', participantConnected);
          newRoom.on('participantDisconnected', participantDisconnected);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          localTracksRef.current.forEach(track =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [options, onError]
  );

  return { room, isConnecting, connect };
}
