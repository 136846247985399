import React, { useState } from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import './ChatInput.css';

export default function ChatInput() {
  const [message, setMessage] = useState('');
  const { room } = useVideoContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, data: any) => {
    console.log('method called');
    event.preventDefault();

    if (message) {
      // Get the LocalDataTrack that we published to the room.
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];

      // Construct a message to send
      const fullMessage = `${room.localParticipant.identity} says: ${message}`;
      console.log('full message here', fullMessage);

      // Send the message
      localDataTrackPublication.track.send(fullMessage);

      // Render the message locally so the local participant can see that their message was sent.
      // enqueueSnackbar(fullMessage);

      //Reset the text field
      setMessage('');
    } else {
      const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
      localDataTrackPublication.track.send('START_ANNOTATION');
    }
  };

  return (
    <form
      autoComplete="off"
      style={{ display: 'flex', alignItems: 'center' }}
      onSubmit={evt => handleSubmit(evt, message)}
    >
      <FormControl>
        <label htmlFor="chat-snack-input" style={{ color: 'black' }}>
          Say something:
        </label>
        <TextField value={message} autoFocus={true} onChange={handleChange} id="chat-snack-input" size="small" />
      </FormControl>
      <Button
        id="send-msg-button"
        type="submit"
        color="primary"
        className="send_msg_btn"
        variant="contained"
        style={{ marginLeft: '0.8em' }}
      >
        Send
      </Button>
    </form>
  );
}
