import React from 'react';
import { Button } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function StartAnnotationButton() {
  // const [isOpen, setIsOpen] = useState(false);
  const { room } = useVideoContext();

  const StartAnnotation = async function() {
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    localDataTrackPublication.track.send('REQUEST_PROVIDE_SCREENSHOT');
  };

  return (
    <Button style={{ backgroundColor: '#187dcd' }} onClick={StartAnnotation}>
      Start Annotation
    </Button>
  );
}
