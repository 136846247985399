'use strict';

//import useWindowDimensions from '../../../state/settings/windowDimensions';
import $ from 'jquery';
const { LocalDataTrack, connect, createLocalTracks } = require('twilio-video');
const colorHash = new (require('color-hash'))();

const participants = document.getElementById('participants');
const video = document.querySelector('#local-participant > video');

/**
 * Setup a LocalDataTrack to transmit mouse coordinates.
 * @returns {LocalDataTrack} dataTrack
 */
async function setupLocalDataTrack() {
  const canvas = await document.getElementById('canvas');

  const dataTrack = new LocalDataTrack();

  let mouseDown;
  let mouseCoordinates;

  window.addEventListener(
    'mousedown',
    () => {
      mouseDown = true;
    },
    false
  );

  window.addEventListener(
    'mouseup',
    () => {
      mouseDown = false;
    },
    false
  );

  window.addEventListener(
    'mousemove',
    event => {
      const { pageX: x, pageY: y } = event;
      mouseCoordinates = { x, y };

      if (mouseDown) {
        const color = colorHash.hex(dataTrack.id);
        drawCircle(canvas, color, x, y);

        dataTrack.send(
          JSON.stringify({
            mouseDown,
            mouseCoordinates,
          })
        );
      }
    },
    false
  );

  return dataTrack;
}

/**
 * Setup a LocalAudioTrack and LocalVideoTrack to render to a <video> element.
 * @param {HTMLVideoElement} video
 * @returns {Promise<Array<LocalAudioTrack|LocalVideoTrack>>} audioAndVideoTrack
 */
async function setupLocalAudioAndVideoTracks(video) {
  const audioAndVideoTrack = await createLocalTracks();
  audioAndVideoTrack.forEach(track => track.attach(video));
  return audioAndVideoTrack;
}

/**
 * Get an Access Token for the specified identity.
 * @param {string} identity
 * @returns {Promise<string>} token
 */

let connectAttempt;
let room;

/**
 * Update the UI in response to disconnecting.
 * @returns {void}
 */

/**
 * Run the app.
 * @returns {Promise<void>}
 */

export async function main() {
  const canvas = await document.getElementById('canvas');

  const dataTrack = setupLocalDataTrack();
  const audioAndVideoTrack = await setupLocalAudioAndVideoTracks(video);

  const tracks = audioAndVideoTrack.concat(dataTrack);
}

/**
 * Handle a connected RemoteParticipant.
 * @param {RemoteParticipant} participant
 * @retruns {void}
 */
export function participantConnected(participant) {
  participant.tracks.forEach(publication => trackPublished(participant, publication));
  participant.on('trackPublished', publication => trackPublished(participant, publication));
  participant.on('trackUnpublished', publication => trackUnpublished(participant, publication));
}

/**
 * Handle a disconnnected RemoteParticipant.
 * @param {RemoteParticipant} participant
 * @returns {void}
 */
export function participantDisconnected(participant) {
  console.log(`RemoteParticipant "${participant.identity}" disconnected`);
  const participantDiv = document.getElementById(participant.sid);
  if (participantDiv) {
    participantDiv.remove();
  }
}

/**
 * Handle a published Track.
 * @param {RemoteParticipant} participant
 * @param {RemoteTrackPublication} publication
 */
function trackPublished(participant, publication) {
  console.log(
    `RemoteParticipant "${participant.identity}" published ${publication.kind} Track ${publication.trackSid}`
  );
  if (publication.isSubscribed) {
    trackSubscribed(participant, publication.track);
  } else {
    publication.on('subscribed', track => trackSubscribed(participant, track));
  }
  publication.on('unsubscribed', track => trackUnsubscribed(participant, track));
}

/**
 * Handle a subscribed Track.
 * @param {RemoteParticipant} participant
 * @param {Track} track
 * @returns {void}
 */
async function trackSubscribed(participant, track) {
  const canvas = await document.getElementById('canvas');
  const dataTrack = new LocalDataTrack();
  const context = canvas.getContext('2d');

  if (track.kind === 'audio' || track.kind === 'video') {
    // track.attach(`${participant.sid} > video`);
  } else if (track.kind === 'data') {
    const color = '#00FFFF';
    track.on('message', data => {
      console.log('from data track subscribed =>', data);

      if (data === 'STOP_ANNOTATION') {
        console.log('data has arrived here');
        var imgTag = document.getElementById('screenshot-base64');
        if (imgTag) {
          imgTag.remove();
        }
        var videoElm = document.getElementsByTagName('video')[0];
        if (videoElm) {
          videoElm.style.display = 'block';
        }
      }

      if (data === 'CLEAR_ANNOTATION') {
        context.clearRect(0, 0, canvas.width, canvas.height);
      }

      if (
        data.charAt(0) !== '{' &&
        data !== 'CLEAR_ANNOTATION' &&
        data !== 'START_ANNOTATION' &&
        data !== 'STOP_ANNOTATION' &&
        data !== 'REQUEST_PROVIDE_SCREENSHOT'
      ) {
        var elem = document.getElementById('snackbar');
        elem.className = 'show';
        elem.innerHTML = data;
        setTimeout(function() {
          elem.className = elem.className.replace('show', '');
        }, 10000);
      }

      const { screenshot } = JSON.parse(data);

      if (screenshot) {
        var elm = document.getElementById('snackbar');
        elm.className = 'show';
        elm.innerHTML = 'Please wait a moment...';
        var image = document.createElement('img');
        image.setAttribute('id', 'screenshot-base64');
        image.src = screenshot;
        image.onload = function() {
          let actualWidth = image.width * 0.3937;
          let actualHeight = image.height * 0.3937;
          localStorage.setItem('imageWidth', actualWidth);
          localStorage.setItem('imageHeight', actualHeight);
          image.width = actualWidth;
          image.height = actualHeight;
          var videoElem = document.getElementsByTagName('video')[0];
          if (videoElem) {
            videoElem.style.display = 'none';
          }
          var videoPortion = document.getElementById('video-portion');
          if (videoPortion) {
            videoPortion.style.width = image.width + 'px';
            videoPortion.style.height = image.height + 'px';
            videoPortion.appendChild(image);
          }
          var canvasElem = document.getElementById('canvas');
          canvasElem.width = image.width;
          canvasElem.height = image.height;
          let input;
          setTimeout(() => {
            let chatBtn = document.getElementById('chat_button');
            if (chatBtn) {
              chatBtn.click();
              console.log('btn found.');
            }
            input = document.getElementById('chat-snack-input');
            if (input) {
              input.value = 'START_ANNOTATION';
            }
            console.log('input', input);
            let msgBtn = $('#send-msg-button');
            console.log('msgBtn', msgBtn);
            if (msgBtn) {
              msgBtn.trigger('click', 'START_ANNOTATION');
              elm.className = elm.className.replace('show', '');
              console.log('clicked');
            }
          }, 2000);
        };
        image.onerror = function() {
          // DatatrackError();
        };
      }

      let width = canvas.width;
      let height = canvas.height;

      const {
        mouseDown,
        mouseCoordinates: { x, y, xM, yM },
      } = JSON.parse(data);
      console.log('screenshot', screenshot);

      if (mouseDown) {
        drawing_line(color, x * width, y * height, xM * width, yM * height, canvas);
      }
    });
  }
}

/**
 * Handle an unpublished Track.
 * @param {RemoteParticipant} participant
 * @param {RemoteTrackPublication} publication
 */
function trackUnpublished(participant, publication) {
  console.log(
    `RemoteParticipant "${participant.identity}" unpublished ${publication.kind} Track ${publication.trackSid}`
  );
}

/**
 * Handle an unsubscribed Track.
 * @param {RemoteParticipant} participant
 * @param {Track} track
 * @returns {void}
 */
function trackUnsubscribed(participant, track) {
  console.log(
    `LocalParticipant unsubscribed from RemoteParticipant "${participant.identity}"'s ${track.kind} Track ${track.sid}`
  );
  if (track.kind === 'audio' || track.kind === 'video') {
    track.detach();
  }
}

/**
 * Draw a circle on the <canvas> element.
 * @param {HTMLCanvasElement} canvas
 * @param {string} color
 * @param {number} x
 * @param {number} y
 * @returns {void}
 */
export async function drawCircle(canvas, color, x, y) {
  //   const canvas = await document.getElementById('canvas');

  const context = await canvas.getContext('2d');
  context.beginPath();
  context.arc(x, y, 1.5, 0, 2 * Math.PI, false);
  context.fillStyle = color;
  context.fill();
  context.strokeStyle = '#000000';
  context.stroke();
}

export async function drawing_line(color, x_start, y_start, x_end, y_end, canvas) {
  //const canvas_new = await document.getElementById('canvas');
  //console.log('canvas_new =>', canvas_new);

  const board = await canvas.getContext('2d');
  board.beginPath();
  board.strokeStyle = color;
  board.lineWidth = 2;
  board.moveTo(x_start, y_start);
  board.lineTo(x_end, y_end);
  board.stroke();
  board.closePath();
}

// Go!
// main().catch(console.error);
