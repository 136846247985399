import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import './SerialNumber.css';

export default function SerialNumber() {
  const [serial_Number, setSerialNumber] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const { roomName, token, serialNumber, call_type } = useParams();
  const [updated, setUpdated] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSerialNumber(serialNumber);
    setMeetingId(roomName);
  }, [roomName, serialNumber]);

  const handleChange = e => {
    setSerialNumber(e.target.value);
  };

  const postSerialNumber = e => {
    e.preventDefault();
    let headerOptions = {
      'Content-Type': 'application/json',
    };

    if (serial_Number) {
      axios({
        url: 'https://api.vyzeo.com/v1/meeting/serial-number',
        // url: 'https://devapi.vyzeo.com/v1/meeting/serial-number',

        method: 'POST',
        data: {
          meetingId: meetingId,
          serialNumber: serial_Number,
        },
        headers: {
          ...headerOptions,
          Authorization: `Bearer ${token}`,
          serialNumber: `${serial_Number}`,
        },
      })
        .then(function(response) {
          if (response && response.status === 200) {
            setUpdated(true);
            enqueueSnackbar('serial number updated successfully.');
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      console.log('NO_SERIAL');
      enqueueSnackbar('Please enter serial number.');
    }
  };

  return (
    <form onSubmit={postSerialNumber} style={{ marginRight: 'auto', display: 'flex' }}>
      {(call_type === '1' || call_type === '2' || serialNumber) && (
        <>
          <FormControl>
            <TextField value={serial_Number} onChange={handleChange} />
          </FormControl>
          <Button
            type="submit"
            className={updated ? 'button-success' : 'button-primary'}
            variant="contained"
            style={{ marginLeft: '0.8em' }}
            // onClick={(e) => postSerialNumber(e)}
          >
            Update Serial Number
          </Button>
        </>
      )}
    </form>
  );
}
