import { useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';
import { useSnackbar } from 'notistack';

export default function DataTrack({ track }: { track: IDataTrack }) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleMessage = (message: any) => {
      console.log('message from dataTrack =>', message);
      // if (message.screenshot) {
      //   localStorage.setItem('screenshotUrl', message.screenshot);
      // }
      //console.log('typeof message =>', typeof message);
      //console.log('(typeof message === string) =>', (typeof message === 'string'));

      if (
        typeof message === 'string' &&
        message.charAt(0) !== '{' &&
        message !== 'CLEAR_ANNOTATION' &&
        message !== 'START_ANNOTATION' &&
        message !== 'REQUEST_PROVIDE_SCREENSHOT'
      ) {
        enqueueSnackbar(message);
      }
    };
    track.on('message', handleMessage);
    return () => {
      track.off('message', handleMessage);
    };
  }, [track, enqueueSnackbar]);

  return null; // This component does not return any HTML, so we will return 'null' instead.
}
