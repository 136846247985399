import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import LocalAudioLevelIndicator from '../MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';

import { useAppState } from '../../state';
import { VYZEO_REDIRECT_URL } from '../../state/settings/config';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import './controls.css';
//import { socket, joinSocketRoom } from '../../socket/socket'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'fixed',
      right: '50%',
      transform: 'translate(50%, 30px)',
      bottom: '50px',
      zIndex: 5,
      transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'hidden',
      maxWidth: 'min-content',
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(50%, 0px)',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
    },
    loadingSpinner: {
      marginLeft: '2.5rem',
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';

  const [modal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState<boolean>(true);

  const { identifierName, roomName, token } = useParams();
  const { getToken, startCall, endCall, isFetching } = useAppState();
  const { isConnecting, connect, room } = useVideoContext();

  //console.log('identifierName =>', identifierName);
  //console.log('roomName =>', roomName);
  //console.log('token =>', token);

  useEffect(() => {
    if (roomState === 'disconnected') {
      setShowModal(false);
    }
    setOpen(true);

    if (roomName && identifierName && visible && roomState === 'disconnected') {
      setVisible(false);
    }
  }, [roomName, identifierName, roomState, visible]);

  const handleJoin = async () => {
    if (identifierName && roomName && token) {
      let twilioToken = await getToken(identifierName, roomName, token);
      console.log('token response form vyzeo', twilioToken);
      setShowModal(true);
      if (twilioToken) {
        //call meeting started api
        startCall(roomName, token);

        setOpen(false);

        //join sdc call
        connect(twilioToken);

        //joinSocketRoom(roomName);

        //socket.on('call_completed', async (message: any) => {
        //    console.log("call_completed called", message);
        //    if (message.meeting_id == roomName) {
        //        room.disconnect();
        //        await endCall(roomName, token);

        //        window.location.assign(VYZEO_REDIRECT_URL);
        //        //window.location.assign(`${process.env.VYZEO_REDIRECT_ENDPOINT}`)
        //    }
        //});
      } else {
        //give popup for error
        //on popup close redirect to vyzeo admin
        window.location.assign(VYZEO_REDIRECT_URL);
      }
    }
  };

  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div>
        <Dialog style={{ display: modal ? 'none' : 'block' }} open={open} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ color: 'white' }} id="form-dialog-title">
            START REMOTE SUPPORT…
          </DialogTitle>
          <div style={{ display: 'flex', marginTop: '15px', padding: '2px 15px' }}>
            <LocalAudioLevelIndicator />
            <div className="mic_level_txt">
              Wait a few seconds and say "Hello". If the microphone on the left stays black, please�
              <a onClick={onRefresh} style={{ color: 'deepskyblue' }} href="">
                refresh
              </a>
              �browser. If you see the green color moving,
              {visible
                ? ' Click "JOIN NOW" and your provider will join shortly.'
                : ' Click "JOIN NOW" to start video call'}{' '}
            </div>
          </div>
          <DialogContent>
            <h5></h5>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isConnecting || !identifierName || !roomName || isFetching}
              type="button"
              className="start_call_btn"
              color="primary"
              onClick={handleJoin}
            >
              Join Now
            </Button>
            {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
          </DialogActions>
        </Dialog>
      </div>

      <div className={clsx(classes.container, { showControls })}>
        <ToggleAudioButton disabled={isReconnecting} />
        {/* <ToggleVideoButton disabled={isReconnecting} /> */}
        {roomState !== 'disconnected' && (
          <>
            <ToggleScreenShareButton disabled={isReconnecting} />
            <EndCallButton />
          </>
        )}
      </div>
    </>
  );
}
