import React, { useEffect } from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

// import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import './app.css';
//import { joinSocketRoom } from './socket/socket'
//import * as io from "socket.io-client";

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

export default function App() {
  const roomState = useRoomState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  // const height = useHeight();

  //useEffect(() => {
  //    joinSocketRoom('heyyy');
  //}, []);

  //useEffect(() => {
  //    window.onbeforeunload = () => {
  //        return "onbeforeunload called";
  //    }
  //}, []);

  //<canvas style={{ position: 'absolute', zIndex: 5 }} id="canvas" width="1400" height="800"></canvas>
  //<canvas id="canvas" style={{ position: 'absolute', zIndex: 5, width: '100%', height: '100%' }} ></canvas>

  const closeModal = () => {
    let err_modal = document.getElementById('suggestion_modal');
    if (err_modal) {
      err_modal.classList.remove('show-modal');
    }
  };

  return (
    <>
      <div id="suggestion_modal" className="modal">
        <div className="modal-content">
          <span id="close_modal" onClick={closeModal} className="close-button">
            ×
          </span>
          <h3>Permission Denied...!!!</h3>
          <p style={{ paddingBottom: '10px' }}>Please follow below steps,</p>
          <ol style={{ padding: '0 15px' }}>
            <li>
              Manually open <strong>System Preferences</strong>
            </li>
            <li>
              Click the <strong>"Security and Privacy"</strong> icon
            </li>
            <li>
              Scroll down and choose <strong>"Screen Recording"</strong> in the sidebar
            </li>
            <li>
              Click the box next to <strong>Google Chrome</strong> to allow screen sharing for your Vyzeo Live session.
              You may need to click the lock icon in the bottom-left to make changes.
            </li>
            <li>
              A pop-up should appear indicating that Google Chrome will need to be quit before it is able to record the
              contents of your screen. Click <strong>"Quit Now"</strong> to exit out of all running instances of Google
              Chrome.
            </li>
            <li>
              <strong>Reopen Chrome</strong>
            </li>
          </ol>
        </div>
      </div>
      <Container style={{ height: '100%' }}>
        <div id="snackbar"></div>
        <MenuBar />
        <Main style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <canvas
            id="canvas"
            width="372.046"
            height="806.298"
            style={{
              position: 'absolute',
              zIndex: 5,
              // left: '49.4%',
              // transform: 'translate(-50%, 0)',
              top: '64px',
            }}
          ></canvas>
          {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
          <Controls />
        </Main>
        <ReconnectingNotification />
      </Container>
    </>
  );
}
