import React from 'react';
//import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
//import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

//import { useAppState } from '../../state';
//import { useParams } from 'react-router-dom';
//import useRoomState from '../../hooks/useRoomState/useRoomState';
//import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
//import { Typography } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import ChatSnackButton from '../ChatSnackButton/ChatSnackButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import ClearAnnotationButton from './ClearAnnotationButton/ClearAnnotationButton';
import StartAnnotationButton from '../StartAnnotationButton/StartAnnotationButton';
import SerialNumber from './SerialNumber';

//import { socket, joinSocketRoom } from '../../socket/socket'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      justifyContent: 'space-between',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <div className={classes.rightButtonContainer}>
          {roomState === 'connected' && <SerialNumber />}
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          {roomState === 'connected' && <ChatSnackButton />}
          {roomState === 'connected' && <ClearAnnotationButton />}
          {roomState === 'connected' && <StartAnnotationButton />}
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
