import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ClearAnnotationButton() {
  // const [isOpen, setIsOpen] = useState(false);
  const { room } = useVideoContext();

  const clearAnnotation = async function() {
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    if (context) {
      localDataTrackPublication.track.send('CLEAR_ANNOTATION');
      localDataTrackPublication.track.send('STOP_ANNOTATION');
      context.clearRect(0, 0, canvas.width, canvas.height);
      var imgTag = document.getElementById('screenshot-base64');
      if (imgTag) {
        imgTag.remove();
      }
      var videoElm = document.getElementsByTagName('video')[0];
      if (videoElm) {
        videoElm.style.display = 'block';
      }
    }
  };

  return <Button onClick={clearAnnotation}>Clear Annotation</Button>;
}
